import React, { useEffect, useState } from 'react'

import tw, { theme, styled } from 'twin.macro'
import { useIntl } from 'react-intl'
//
import { InputField } from '~storybook/form/input/input'
import Icons from '~storybook/icons/index'
import InputOptionGroup from '~storybook/form/input-option/index'
import Calendar from '~storybook/form/calendar/index'
import Button from '~storybook/button/index'
import FiltersDrawer from './filters-drawer'
import useWindowDimensions from '~utils/responsive'

const IconButton = styled.button`
  ${tw`text-white font-medium py-3 px-4 rounded-r-sm transition-colors duration-200 flex justify-center min-w-button`}
  ${tw`bg-c-filters-iconButton-bg hover:bg-c-filters-iconButton-hoverBg focus:bg-c-filters-iconButton-focusBg active:bg-c-filters-iconButton-activeBg focus:outline-none px-3 min-w-0`}
  ${({ disabled }) => disabled && tw`opacity-40 cursor-default hover:bg-c-filters-iconButton-bg`}

  width: 48px;
  height: 46px;
`

const InputWrapper = styled.div`
  width: calc(100%);
`

const AccordionItemWrapper = styled(({ expanded, ...other }) => <div {...other} />)`
  ${tw`block border-b border-solid border-c-filters-iconButton-accordionBorder pt-6 pb-6`}
  ${({ expanded }) => expanded && tw`pb-6`}
`
const AccordionItem = styled(({ active, ...other }) => <button type="button" {...other} />)`
  ${tw`w-full bg-transparent cursor-pointer transition-colors duration-200 flex justify-between`}
  ${tw`outline-none focus:outline-none no-underline hover:no-underline py-0 mt-0 mb-0`}
`

const SectionTitle = styled.h5`
  ${tw`text-lg font-medium text-c-filters-iconButton-text cursor-pointer`}
`

const Accordion = ({ label = '', children }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <AccordionItemWrapper expanded={isExpanded}>
      <AccordionItem onClick={() => toggleAccordion()}>
        <SectionTitle>{label}</SectionTitle>
        {isExpanded ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
      </AccordionItem>
      {isExpanded && <div className="p-0 m-0 pt-4">{children}</div>}
    </AccordionItemWrapper>
  )
}

export const FilterContents = ({
  title = null,
  filters: receivedFilters = {},
  onFilter = () => {
    /* noop */
  },
  onSearch = () => {
    /* noop */
  }
}) => {
  const intl = useIntl()
  const [searchQuery, setSearchQuery] = useState('')
  // const [haserror, setHaserror] = useState(false)

  const [options, setOptions] = useState(receivedFilters)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const onUpdateFiltersHandler = () => {
    const newFilters = {
      searchQuery,
      filters: options,
      startDate,
      endDate
    }
    onFilter(newFilters)
  }

  const onChangeHandler = (str) => {
    setSearchQuery(str)
  }

  const onSearchHandler = (str) => {
    onSearch(str)
  }

  const onChangeOptionsHandler = (newOptions, id) => {
    const optionsUpdated = []
    // eslint-disable-next-line no-unused-expressions
    options?.forEach((opt) => {
      if (opt?.id === id) {
        optionsUpdated.push({ ...opt, options: newOptions })
      } else {
        optionsUpdated.push(opt)
      }
    })
    setOptions(optionsUpdated)
  }

  const startDateChangeHandler = (date) => {
    setStartDate(date?.startDate)
  }

  const endDateChangeHandler = (date) => {
    setEndDate(date?.startDate)
  }

  const selectedDateOption = options?.find((opt) => opt?.id === 'dates')?.options?.find((opt) => opt?.checked)

  const getFilterStatus = () => {
    let disable = true
    const isDateRangeFieldSelected = selectedDateOption?.name === 'range'

    options.forEach((sec) => {
      if (disable) {
        if (sec?.options?.some((opt) => opt?.checked)) {
          disable = false
        }
      }
    })

    if (isDateRangeFieldSelected) {
      if (!startDate && !endDate) {
        disable = true
      }
    }

    return disable
  }

  return (
    <>
      <>
        <SectionTitle>{title || intl?.formatMessage({ id: 'filter.searchNews' })}</SectionTitle>
        <div className="flex flex-row mx-auto mt-3 ">
          <InputWrapper>
            <InputField
              search
              field={{
                id: 'search-filter-field',
                value: searchQuery || '',
                placeholder: intl?.formatMessage({ id: 'filter.searchPlaceholder' })
              }}
              onChange={(str) => onChangeHandler(str)}
              onKeyDown={(e) => e.key === 'Enter' && onSearchHandler(searchQuery)}
            />
          </InputWrapper>
          <IconButton onClick={() => onSearchHandler(searchQuery)}>
            <Icons.Search />
          </IconButton>
        </div>

        {options.map((opt) => (
          <Accordion key={opt?.id} label={opt?.label}>
            <InputOptionGroup
              {...{
                field: {
                  name: opt?.id
                },
                options: opt?.options,
                radios: opt?.type === 'radio',
                onUpdate: (newOptions) => {
                  onChangeOptionsHandler(newOptions, opt?.id)
                }
              }}
            />
            {opt?.id === 'dates' && selectedDateOption?.name === 'range' && (
              <>
                <div className="pt-10 pb-5">
                  <Calendar
                    {...{
                      placeholder: intl?.formatMessage({ id: 'filter.datePlaceholder' }),
                      handleChange: startDateChangeHandler,
                      name: 'startDate',
                      label: intl?.formatMessage({ id: 'filter.startDate' }),
                      dateRange: false,
                      error: intl?.formatMessage({ id: 'filter.startDateError' }),
                      dates: {
                        startDate: startDate && new Date(startDate)
                      }
                    }}
                  />
                </div>
                <div>
                  <Calendar
                    {...{
                      placeholder: intl?.formatMessage({ id: 'filter.datePlaceholder' }),
                      handleChange: endDateChangeHandler,
                      name: 'endDate',
                      label: intl?.formatMessage({ id: 'filter.endDate' }),
                      dateRange: false,
                      error: intl?.formatMessage({ id: 'filter.endDateError' }),
                      dates: {
                        startDate: endDate && new Date(endDate)
                      }
                    }}
                  />
                </div>
              </>
            )}
          </Accordion>
        ))}
        <div className="pt-8">
          <Button disabled={getFilterStatus()} onClick={onUpdateFiltersHandler}>
            {intl?.formatMessage({ id: 'filter.applyFilters' })}
          </Button>
        </div>
      </>
    </>
  )
}

export const Filters = ({ title, filters, onSearch, onFilter }) => {
  const { width } = useWindowDimensions()
  const maxWidth = parseInt(`${theme`screens.lg`}`.replace('px', ''), 10)

  const [key, setKey] = useState('filter-key_0')

  if (!filters) {
    return null
  }

  useEffect(() => {
    setKey(`search-key_${parseInt(key?.split('_')?.[1], 10) + 1}`)
  }, [])

  return width < maxWidth ? (
    <div key={key} className="w-full">
      <FiltersDrawer
        title={title}
        filters={filters}
        onFilter={onFilter}
        onSearch={onSearch}
        FilterContents={FilterContents}
      />
    </div>
  ) : (
    <div key={key} className="w-full">
      <FilterContents title={title} filters={filters} onFilter={onFilter} onSearch={onSearch} />
    </div>
  )
}
