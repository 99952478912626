import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import en from 'date-fns/locale/en-GB'
import nl from 'date-fns/locale/nl'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import tw, { theme, styled } from 'twin.macro'
//
import { Heading, Label, SuperParagraph, Tag } from '~storybook/blocks/typography/index'
import { ImageProxy } from '~storybook/image/image'
import Icons from '~storybook/icons/index'
import NavLink from '~utils/navlink'
import { formatTZ } from '~utils/dates'

const localesObj = {
  en,
  nl,
  fr,
  de
}

const TertiaryStyle = styled(({ hasIcon, isSection, ...other }) => <NavLink {...other} />)`
  ${tw`text-c-newsfeed-card-secondary font-medium py-0 mb-0 mt-4 no-underline cursor-pointer transition-colors duration-200 flex justify-center`}
  ${tw`bg-transparent hover:text-c-newsfeed-card-primary hover:underline`}
  ${({ hasIcon }) => hasIcon && tw`pr-4 justify-start`}

  svg {
    margin-left: 10px;
    display: inline-block;
  }
`

const ImageWrapper = styled.div`
  &,
  .gatsby-image-wrapper {
    width: 278px;
    max-width: 278px;
    max-height: 185px;

    @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
      max-width: 243px;
      max-height: 162px;
    }

    @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
      max-height: 218px;
      width: 100%;
      max-width: 100%;
    }
  }
`

export const NewsfeedCard = ({
  title = '',
  text = '',
  image = null,
  category = '',
  date = null,
  cta = {},
  mode = 'card'
}) => {
  const intl = useIntl()

  const dateformat = intl?.locale === 'en' ? 'do LLLL yyyy | HH:mm' : 'd LLLL yyyy | HH:mm'

  const dateStr = date && `${formatTZ(date, dateformat, { locale: localesObj?.[intl?.locale] })}`

  return (
    <div className="flex flex-col md:flex-row">
      {image && image?.url && (
        <ImageWrapper className="mb-4">
          <NavLink to={cta?.url}>
            <ImageProxy
              className="absolute w-full h-full top-0 left-0"
              objectFit="cover"
              {...{
                url: image?.url,
                type: 'fluid',
                params: {
                  mw: 278,
                  mh: 185,
                  g: 'sm',
                  r: 'fill'
                }
              }}
            />
          </NavLink>
        </ImageWrapper>
      )}
      <div className={image && image?.url ? 'md:px-7' : ''}>
        <NavLink
          className="flex flex-col flex-1 cursor-pointer no-underline hover:no-underline text-c-newsfeed-card-primary hover:text-c-newsfeed-card-primary justify-between"
          to={cta?.url}
        >
          {(dateStr || category) && (
            <div className="mb-4 flex">
              {category && (
                <div>
                  <Tag className="cursor-pointer text-c-newsfeed-card-primary">{category}</Tag>
                </div>
              )}
              {date && (
                <div className={category ? 'ml-2' : ''}>
                  <Label className="cursor-pointer text-c-newsfeed-card-secondary">{dateStr}</Label>
                </div>
              )}
            </div>
          )}
          {title && <Heading className="font-medium" level="h3" text={title} />}
          {text && <SuperParagraph text={text} />}
        </NavLink>
        {mode === 'card' && cta?.url && (
          <TertiaryStyle to={cta?.url} hasIcon>
            <div className="block">
              {cta?.label || <FormattedMessage id="news.readMore" />}
              <Icons.ArrowRight />
            </div>
          </TertiaryStyle>
        )}
      </div>
    </div>
  )
}

export default NewsfeedCard
