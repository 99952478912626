import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import tw, { theme, styled } from 'twin.macro'
import { useIntl } from 'react-intl'
//
import Icons from '~storybook/icons/index'

const FilterButton = styled.button(() => [
  tw`justify-between flex flex-row relative w-full pr-10 py-2 px-2 text-lg min-h-input text-c-filter-button-text border border-solid border-c-filter-button-border placeholder-c-filter-button-placeholder rounded-sm outline-none`,
  tw`hover:(border-c-filter-button-borderHover)`,
  tw`focus:(border-c-filter-button-borderHover outline-none)`
])
const CloseButton = styled.button(() => [tw`py-2 px-2 rounded-sm outline-none`, tw`focus:(outline-none)`])

const StyledDrawerContainer = styled.div`
  @media (min-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
    width: 100%;
    flex-shrink: 0;
  }
`

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    ${tw`bg-c-filter-button-drawer`};
  }
`

function FiltersDrawer({ window, filters, title = null, onFilter, onSearch, FilterContents }) {
  const intl = useIntl()

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <div className="flex">
      <FilterButton
        aria-label={intl.formatMessage({ id: 'filters.ariaLabelOpen' })}
        edge="start"
        onClick={handleDrawerToggle}
      >
        {title || intl.formatMessage({ id: 'filter.title' })}
        <Icons.Filter />
      </FilterButton>
      <StyledDrawerContainer aria-label={intl.formatMessage({ id: 'filters.ariaLabel' })}>
        <Hidden mdUp implementation="css">
          <StyledDrawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <div className="p-6">
              <div className="text-right">
                <CloseButton onClick={handleDrawerToggle}>
                  <Icons.Close />
                </CloseButton>
              </div>
              <FilterContents
                filters={filters}
                onSearch={(str) => {
                  handleDrawerToggle()
                  onSearch(str)
                }}
                onFilter={(filterOpt) => {
                  handleDrawerToggle()
                  onFilter(filterOpt)
                }}
              />
            </div>
          </StyledDrawer>
        </Hidden>
      </StyledDrawerContainer>
    </div>
  )
}

export default FiltersDrawer
