import { useState, useEffect } from 'react'
//
import { isSSR } from './ssr'

function getWindowDimensions() {
  let width = 0
  let height = 0

  if (!isSSR) {
    width = window?.innerWidth
    height = window?.innerHeight
  }

  return {
    width,
    height
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
