import { format, addHours } from 'date-fns'

const DEFAULT_TZ = 'Europe/Brussels'
const HOURS_OFFSET = 2

export const formatTZ = (date, fmt, params = {}) => {
  if (!params.timeZone) {
    params.timeZone = DEFAULT_TZ
  }

  return format(addHours(date, HOURS_OFFSET), fmt, params)
}
